import {
  create_new_org,
  get_organizations,
  get_organizations_vendor,
  update_new_org,
  get_contacts,
  get_contacts_salereps,
  save_contact,
  update_contact,
  delete_contact,
} from "@/helpers/api/indexv2.js";
import {
  getQuotesAndOpenOrders,
  getCompletedOrders,
  getHistoryOrders,
  getOrganizationConfig,
  createOrganizationConfig,
  updateOrganizationConfig,
  deleteOrganizationConfig,
  getTransactionHistoryByCostumer,
  createHoldRate,
  updateHoldRate,
  createQuota,
  updateQuota,
} from "@/helpers/api/Organizations/index.js";
// import { createHoldRate, getHoldRate } from "@/helpers/api/Organizations/index.js";

// import {
//   makePublicFile,
//   deleteFile,
//   uploadFile,
//   getSignedURL,
//   getListOfFilesByOrganization,
// } from "@/helpers/api/Organizations/index.js";

const getOrgTypeName = (value) => {
  switch (value) {
    case "C":
      return "Customer";
      break;
    case "V":
      return "Vendor";
      break;
    case "D":
      return "Depot";
      break;
    case "OPR":
      return "Operator";
      break;
    case "OTH":
      return "Other";
      break;
  }
};
export default {
  namespaced: true,
  state: {
    list_organizations: [],
    list_organizations_vendor: [],
    list_orgContacts: [],
    list_orgContacts_salereps: [],
    searchInput: "",
    flagNew: false,
    list_of_files: [],
    quotesAndOpenOrders: [],
    completedOrders: [],
    historyOrders: [],
    tabSelected: "",
    orgConfig: {},
    transactionHistory: [],
  },
  mutations: {
    setListOrganizations(state, newState) {
      state.list_organizations = newState;
    },
    setListOrganizationsVendor(state, newState) {
      state.list_organizations_vendor = newState;
    },
    setSearchInput(state, newState) {
      state.searchInput = newState;
    },
    setListOrgContacts(state, newState) {
      state.list_orgContacts = newState;
    },

    setListOrgContactsSaleReps(state, newState) {
      state.list_orgContacts_salereps = newState;
    },

    setFlagNew(state, newState) {
      state.flagNew = newState;
    },
    setQuotesAndOpenOrders(state, newState) {
      state.quotesAndOpenOrders = newState;
    },
    setCompletedOrders(state, newState) {
      state.completedOrders = newState;
    },
    setHistoryOrders(state, newState) {
      state.historyOrders = newState;
    },
    setTabSelected(state, newState) {
      state.tabSelected = newState;
    },
    setOrgConfig(state, newState) {
      state.orgConfig = newState;
    },
    setTransactionHistory(state, newState) {
      state.transactionHistory = newState;
    },
  },
  actions: {
    async getsALLOrganizations({ commit }) {
      // commit("setSpinnerShow", true, { root: true });
      try {
        let res = await Promise.all([
          get_organizations(), // all org in a entity
          get_organizations_vendor(), //all org (whether or not it is in an entity) of type vendor
        ]);

        commit("setListOrganizations", res[0].data.data);
        commit("setListOrganizationsVendor", res[1].data.data);
        // commit(
        //   "setSnackbar",
        //   {
        //     text: "Organizations fetched!",
        //     status: "success",
        //     show: "showNav",
        //   },
        //   { root: true },
        // );
      } catch (error) {
        // commit(
        //   "setSnackbar",
        //   {
        //     text: `Something went wrong. ${
        //       error ? error.data.message : "Server problem"
        //     }`,
        //     status: "danger",
        //     show: "showNav",
        //   },
        //   { root: true },
        // );
        console.log(error);
      }

      return;
      // commit("setSpinnerShow", false, { root: true });
    },
    async getsOrganizations({ commit }) {
      // commit("setSpinnerShow", true, { root: true });
      try {
        const res = await get_organizations(); // all org in a entity
        commit("setListOrganizations", res.data.data);

        // commit(
        //   "setSnackbar",
        //   {
        //     text: "Organizations fetched!",
        //     status: "success",
        //     show: "showNav",
        //   },
        //   { root: true },
        // );
      } catch (error) {
        // commit(
        //   "setSnackbar",
        //   {
        //     text: `Something went wrong. ${
        //       error ? error.data.message : "Server problem"
        //     }`,
        //     status: "danger",
        //     show: "showNav",
        //   },
        //   { root: true },
        // );
        console.log(error);
      }

      return;
      // commit("setSpinnerShow", false, { root: true });
    },
    async createNewOrg({ commit }, { data, flag }) {
      
      commit("setSpinnerShow", true, { root: true });
      let response;
      try {
        if (flag) {
          response = await create_new_org(data);
        } else {
          response = await update_new_org(data);
        }
        commit(
          "setSnackbar",
          {
            text: response.message,
            status: "success",
            show: "showNav",
            timeout: 2000,
          },
          { root: true }
        );
        let orgs = await get_organizations();
        commit("setListOrganizations", orgs.data.data);
        commit("setSpinnerShow", false, { root: true });
      } catch (error) {
        console.log("error");
        commit(
          "setSnackbar",
          {
            text: `Something went wrong. ${
              error ? error.data.message : "Server problem"
            }`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
        commit("setSpinnerShow", false, { root: true });

        throw error;
      }
      return response;
    },
    async getContacts({ commit }, { org }) {
      if (org.organization_id) {
        commit("setSpinnerShow", true, { root: true });
        try {
          let { data } = await get_contacts(org.organization_id);

          commit("setListOrgContacts", data);
          commit(
            "setSnackbar",
            {
              text: "Organizations fetched!",
              status: "success",
              show: "showNav",
            },
            { root: true }
          );
        } catch (error) {
          commit(
            "setSnackbar",
            {
              text: `Something went wrong. ${
                error ? error.data.message : "Server problem"
              }`,
              status: "danger",
              show: "showNav",
            },
            { root: true }
          );
          console.log(error);
        }
        commit("setSpinnerShow", false, { root: true });
      } else {
        commit(
          "setSnackbar",
          {
            text: `No data found.
          `,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
      }
    },
    async saveContact({ commit }, { org, flag, data }) {
      commit("setSpinnerShow", true, { root: true });
      let response;
      try {
        if (flag) {
          response = await save_contact({
            organization_id: org.organization_id,
            data,
          });
        } else {
          response = await update_contact({
            organization_id: org.organization_id,
            data,
          });
        }
        commit(
          "setSnackbar",
          {
            text: response.message,
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
        let contacts = await get_contacts(org.organization_id);

        commit("setListOrgContacts", contacts.data);
      } catch (error) {
        console.log("error");
        commit(
          "setSnackbar",
          {
            text: `Something went wrong. ${
              error ? error.data.message : "Server problem"
            }`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
        console.log(error);
      }
      commit("setSpinnerShow", false, { root: true });
      return response;
    },
    async deleteContact({ commit }, { contact_id, organization_id }) {
      commit("setSpinnerShow", true, { root: true });
      let response;
      try {
        response = await delete_contact({
          contact_id,
        });

        commit(
          "setSnackbar",
          {
            text: response.message,
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
        let contacts = await get_contacts(organization_id);

        commit("setListOrgContacts", contacts.data);
      } catch (error) {
        console.log("error");
        commit(
          "setSnackbar",
          {
            text: `Something went wrong. ${
              error ? error.data.message : "Server problem"
            }`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
        console.log(error);
      }
      commit("setSpinnerShow", false, { root: true });
      return response;
    },
    async getQuotesAndOpenOrders({ commit }, { custID }) {
      commit("setSpinnerShow", true, { root: true });
      try {
        let { data } = await getQuotesAndOpenOrders(custID);
        console.log("quotes and open orders", data);

        commit("setQuotesAndOpenOrders", data);
        commit(
          "setSnackbar",
          {
            text: "Fetched Quotes and Open orders successfully!",
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
      } catch (error) {
        console.log("error: ", error);
        commit(
          "setSnackbar",
          {
            text: `Something went wrong. ${
              error ? error.data.message : "Server problem"
            }`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
      }
      commit("setSpinnerShow", false, { root: true });
    },
    async getCompletedOrders({ commit }, { custID }) {
      commit("setSpinnerShow", true, { root: true });
      try {
        let { data } = await getCompletedOrders(custID);
        console.log("completed orders", data);

        commit("setCompletedOrders", data);
        commit(
          "setSnackbar",
          {
            text: "Fetched completed orders successfully!",
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong. ${
              error ? error.data.message : "Server problem"
            }`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
        console.log(error);
      }
      commit("setSpinnerShow", false, { root: true });
    },
    async getHistoryOrders({ commit }, { custID }) {
      commit("setSpinnerShow", true, { root: true });
      try {
        let { data } = await getHistoryOrders(custID);
        commit("setHistoryOrders", data);
        commit(
          "setSnackbar",
          {
            text: "Fetched completed orders successfully!",
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong. ${
              error ? error.data.message : "Server problem"
            }`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
        console.log(error);
      }
      commit("setSpinnerShow", false, { root: true });
    },
    async getOrganizationConfig({ commit }) {
      commit("setSpinnerShow", true, { root: true });
      try {
        let { data } = await getOrganizationConfig();
        commit("setOrgConfig", data.data.length > 0 ? data.data[0] : {});
        // commit(
        //   "setSnackbar",
        //   {
        //     text: "Got organization config!",
        //     status: "success",
        //     show: "showNav",
        //   },
        //   { root: true },
        // );
      } catch (error) {
        // commit(
        //   "setSnackbar",
        //   {
        //     text: `Something went wrong. ${
        //       error ? error.data.message : "Server problem"
        //     }`,
        //     status: "danger",
        //     show: "showNav",
        //   },
        //   { root: true },
        // );
        console.log(error);
      }
      commit("setSpinnerShow", false, { root: true });
    },
    async createOrganizationConfig({ commit }, { release_option }) {
      commit("setSpinnerShow", true, { root: true });
      try {
        let { pass } = await createOrganizationConfig(release_option);
        let { data } = await getOrganizationConfig();
        commit("setOrgConfig", data.data.length > 0 ? data.data[0] : {});
        commit(
          "setSnackbar",
          {
            text: "Saved the organization config!",
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong. ${
              error ? error.data.message : "Server problem"
            }`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
        console.log(error);
      }
      commit("setSpinnerShow", false, { root: true });
    },
    async updateOrganizationConfig({ commit }, { release_option, id }) {
      console.log("update");
      commit("setSpinnerShow", true, { root: true });
      try {
        let response = await updateOrganizationConfig(release_option, id);
        console.log(response);
        let { data } = await getOrganizationConfig();
        commit("setOrgConfig", data.data.length > 0 ? data.data[0] : {});
        commit(
          "setSnackbar",
          {
            text: "Updated the organization config!",
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong. ${
              error ? error.data.message : "Server problem"
            }`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
        console.log(error);
      }
      commit("setSpinnerShow", false, { root: true });
    },

    async tabSelected({ commit }, { title }) {
      commit("setTabSelected", title);
    },
    async getTransactionHistoryByCostumer({ commit }, { custID }) {
      commit("setSpinnerShow", true, { root: true });
      try {
        let { data } = await getTransactionHistoryByCostumer(custID);
        commit("setTransactionHistory", data.data);
        commit(
          "setSnackbar",
          {
            text: "Got organization config!",
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong. ${
              error ? error.data.message : "Server problem"
            }`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
        console.log(error);
      }
      commit("setSpinnerShow", false, { root: true });
    },
    async getContactsSaleReps({ commit }, { org }) {
      if (org.organization_id) {
        commit("setSpinnerShow", true, { root: true });
        try {
          let { data } = await get_contacts_salereps(org.organization_id);

          console.log("setListOrgContactsSaleReps", data);

          commit("setListOrgContactsSaleReps", data);
          commit(
            "setSnackbar",
            {
              text: "Sales Reps fetched!",
              status: "success",
              show: "showNav",
            },
            { root: true }
          );
        } catch (error) {
          // commit(
          //   "setSnackbar",
          //   {
          //     text: `Something went wrong. ${
          //       error ? error.data.message : "Server problem"
          //     }`,
          //     status: "danger",
          //     show: "showNav",
          //   },
          //   { root: true },
          // );
          console.log(error);
        }
        commit("setSpinnerShow", false, { root: true });
      } else {
        commit(
          "setSnackbar",
          {
            text: `No data found.
          `,
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
      }
    },
    async createGbRate({ commit }, { data, flag }) {
      commit("setSpinnerShow", true, { root: true });
      try {
        flag ? await createHoldRate(data) : updateHoldRate(data);

        commit(
          "setSnackbar",
          {
            text: flag
              ? "Gate Buy Hold Rate created"
              : "Gate Buy Hold Rate updated",
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong. ${
              error && error.response
                ? error.response.data.message
                : "Server problem"
            }`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
      }
      commit("setSpinnerShow", false, { root: true });
    },
    async createGbQuota({ commit }, { data, flag }) {
      commit("setSpinnerShow", true, { root: true });
      try {
        flag ? await createQuota(data) : updateQuota(data);

        commit(
          "setSnackbar",
          {
            text: flag
              ? "Gate Buy Quota created"
              : "Gate Buy Quota updated",
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong. ${
              error && error.response
                ? error.response.data.message
                : "Server problem"
            }`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
      }
      commit("setSpinnerShow", false, { root: true });
    },
  },
  getters: {
    filteredListCustomers(state) {
      let filtData = [];

      if (state.list_organizations.length) {
        filtData = state.list_organizations.filter(
          ({ organization_type }) => organization_type === "C"
        );

        filtData = filtData.map((org) => {
          let newCustomer = { ...org };
          newCustomer.organization_type = "Customer";
          return newCustomer;
        });
      }
      return filtData;
    },
    filteredListVendor(state) {
      let filtData = [];

      if (state.list_organizations.length) {
        filtData = state.list_organizations.filter(
          ({ organization_type }) => organization_type !== "C"
        );

        // filtData = filtData.map((org) => {
        //   let newCustomer = { ...org };
        //   newCustomer.organization_type = "Customer";
        //   return newCustomer;
        // });
      }
      return filtData;
    },
    ListOrgTypes(state) {
      let filtData = [];
      if (state.list_organizations.length) {
        filtData = state.list_organizations.map((org) => {
          let newOrg = { ...org };
          newOrg.organization_type = getOrgTypeName(org.organization_type);
          return newOrg;
        });
      }
      return filtData;
    },
    filteredListOrgs(state, getters) {
      let searchInput = state.searchInput === null ? "" : state.searchInput;
      if (getters.ListOrgTypes.length) {
        const filtData = getters.ListOrgTypes.filter((org) => {
          if (
            (org.organization_descr &&
              org.organization_descr
                .toLowerCase()
                .startsWith(searchInput.toLowerCase())) ||
            (org.organization_type &&
              org.organization_type
                .toLowerCase()
                .startsWith(searchInput.toLowerCase())) ||
            (org.organization_id &&
              org.organization_id
                .toString()
                .toLowerCase()
                .startsWith(searchInput.toLowerCase())) ||
            (org.primary_email &&
              org.primary_email
                .toString()
                .toLowerCase()
                .startsWith(searchInput.toLowerCase()))
          ) {
            return org;
          }
        });

        return filtData;
      }
    },
  },
};
