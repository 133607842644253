import Vue from "vue";
import {
  update_equipment_on_order_single,
  update_equipment_on_order_batch,
  get_payment_details,
  get_equipment_on_order,
  get_release_details,
  get_invoice_meta_details,
  get_comments,
  update_comment,
  delete_comment,
  create_comment,
  get_notes_view_acces,
  update_invoice_meta,
  create_invoice_meta,
  delete_invoice_meta,
  available_container_no,
  trans_log_order,
  remote_upload_pdf_to_order,
  set_qty_line_item,
  remove_line_item,
  update_order_pipeline,
  get_single_credit,
  delete_EOO,
  get_cust_balance_credit,
  get_releases_canceled,
  get_credit_balance,
  save_added_line_items,
  delete_payment,
  send_releases,
  create_dv_container,
  get_order_type,
  remove_line_item_bb_soft,
} from "@/helpers/api/Orders/index.js";

import {
  get_single_order_details_by_docNo,
  get_single_order_headers_by_docNo,
  filter_inventory,
  change_sent_value_single_order_details,
  get_inventory_qty_summs,
  get_single_order_details_by_docNo_docType_inv,
  get_single_order_headers_by_docNo_docType_inv,
  delete_container_comment,
  update_container_comment,
  get_container_comment,
  update_mr_comment_bos_display,
  sent_serve_bos_email,
} from "@/helpers/api/indexv2.js";

import { getCustomFieldByOrder } from "@/helpers/api/CustomFields/index.js";
import { parseFromIsoToFormat } from "@/helpers/mocks/dates.js";

export default {
  namespaced: true,
  state: {
    invoiceMetaDetails: [],
    lineItems: [],
    paymentDetails: [],
    releaseDetails: [],
    EooDetails: [],
    itemSelected: {},
    indexSelected: null,
    stepperIndex: null,
    customFields: [],
    EOO: [],
    Equipments: [],
    panelsOpen: [],
    comments: [],
    viewAccess: [],
    hasEOOLineItems: {},
    pickedUpLineItems: {},
    objPickedUpArr: [],
    infoMessage: null,
    creditData: null,
    eooIndex: null,
    customerAccountBalance: 0,
    creditBalance: [],
    equipmentMRComments: null,
    BOSOption: false,
    releaseCanceled: [],
    tabSelected: "",
    flagAddLineItems: false,
    addedLineItemSelected: null,
    flagAddingDVContainer: false,
    DVContainer: null,
    affectedDVContainer: null,
    orderTypeDetails: null,
  },
  mutations: {
    setAffectedDVContainer(state, newState) {
      state.affectedDVContainer = newState;
    },
    setFlagAddingDVContainer(state, newState) {
      state.flagAddingDVContainer = newState;
    },
    setDVContainer(state, newState) {
      state.DVContainer = newState;
    },
    setInvoiceMetaDetails(state, newState) {
      state.invoiceMetaDetails = newState;
    },

    setPanelsOpen(state, newState) {
      state.panelsOpen = newState;
    },

    setLineItems(state, newState) {
      state.lineItems = newState;
    },

    setPaymentDetails(state, newState) {
      state.paymentDetails = newState;
    },

    setReleaseDetails(state, newState) {
      state.releaseDetails = newState;
    },

    setEooDetails(state, newState) {
      state.EooDetails = newState;
    },

    setItemSelected(state, newState) {
      state.itemSelected = newState;
    },
    setIndexSelected(state, newState) {
      state.indexSelected = newState;
    },
    setStepperIndex(state, newState) {
      state.stepperIndex = newState;
    },
    setCustomFields(state, newState) {
      state.customFields = newState;
    },
    setEOO(state, newState) {
      state.EOO = newState;
    },
    updateEOO(state, { index, value }) {
      Vue.set(state.EOO, index, value);
    },
    setEquipments(state, newState) {
      state.Equipments = newState;
    },
    updateEquipments(state, { index, value }) {
      Vue.set(state.Equipments, index, value);
    },
    setComments(state, newState) {
      state.comments = newState;
    },
    setViewAccess(state, newState) {
      state.viewAccess = newState;
    },
    setHasEOOLineItems(state, newState) {
      state.hasEOOLineItems = newState;
    },
    setPickedUpLineItems(state, newState) {
      state.pickedUpLineItems = newState;
    },
    setObjPickedUpArr(state, newState) {
      state.objPickedUpArr = newState;
    },
    setInfoMessage(state, newState) {
      state.infoMessage = newState;
    },
    setCreditData(state, newState) {
      state.creditData = newState;
    },
    setEOOIndex(state, newState) {
      state.eooIndex = newState;
    },
    setCustomerAccountBalance(state, newState) {
      state.customerAccountBalance = newState;
    },
    setCreditBalance(state, newState) {
      state.creditBalance = newState;
    },
    setEquipmentMRComments(state, newState) {
      state.equipmentMRComments = newState;
    },
    setReleaseCanceled(state, newState) {
      state.releaseCanceled = newState;
    },
    setBOSOption(state, newState) {
      state.BOSOption = newState;
    },
    setFlagAddLineItems(state, newState) {
      state.flagAddLineItems = newState;
    },
    setAddedLineItemSelected(state, newState) {
      state.addedLineItemSelected = newState;
    },
    setOrderTypeDetails(state, newState) {
      state.orderTypeDetails = newState;
    }
  },
  getters: {
    orderType(state) {
      return state.orderTypeDetails
        ? {
            order_type_desc: state.orderTypeDetails.code,
          }
        : false;
    },
    orderTypeDesc(state) {
      return state.orderTypeDetails ? state.orderTypeDetails.typeDesc : "";
    },
    order_bal(state) {
      let total = 0;
      const payments = state.paymentDetails.filter((x) => x.type !== "DV");

      payments.map((x) => (total += parseFloat(x.amount_paid)));
      return total;
    },
    tabDetails(state) {
      return state.tabSelected.details;
    },
    panelsOpen(state) {
      return state.panelsOpen;
    },
    uniqueDepotLineItems(state) {
      const uniqueLineItemsByDepot = [];
      state.lineItems.forEach((x) => {
        if (
          uniqueLineItemsByDepot.find(
            (value) =>
              value.Location === x.Location &&
              x.sizeDescr === value.sizeDescr &&
              x.condDescr === value.condDescr &&
              x.typeCode === value.typeCode,
          ) === undefined
        ) {
          uniqueLineItemsByDepot.push(x);
        }
      });
      return uniqueLineItemsByDepot;
    },
    entriesUnique(state, getters) {
      const uniqueLineItemsByDepot = {};
      getters.uniqueDepotLineItems.forEach((x, i) => {
        uniqueLineItemsByDepot[
          `${x.Location}${x.sizeDescr}${x.condDescr}${x.typeCode}`
        ] = state.Equipments[i];
      });
      return uniqueLineItemsByDepot;
    },
    releaseDetailsParse(state) {
      return state.releaseDetails.map((x) => {
        x.release_date = parseFromIsoToFormat(x.release_date, "yyyy-LL-dd");
        return x;
      });
    },
    isTBD(state) {
      if (!state.EOO.length) return "";
      let isTBD = 0,
        isNONTBD = 0,
        eooLength = 0;
      for (const eoo of state.EOO) {
        eooLength += eoo.length;
        for (const value of eoo) {
          if (value.ContainerNo && value.release_id) {
            let release = state.releaseDetails.filter(
              (x) => x.id === value.release_id,
            );

            if (!value.equipmentCreatedDate) return "";

            if (release[0].dateCreated > value.equipmentCreatedDate) {
              isNONTBD += 1;
            } else {
              isTBD += 1;
            }
          } else {
            if (value.ContainerNo) {
              isNONTBD += 1;
            } else if (value.release_id) {
              isTBD += 1;
            }
          }
        }
      }

      if (
        (isTBD > 0 && isNONTBD > 0) ||
        (isNONTBD > 0 && isNONTBD !== eooLength)
      )
        return "MIXED";
      if (isTBD === 0 && isNONTBD === 0) return "TBD";
      if (isTBD === 0) return "DETERMINED";

      return "TBD";
    },
    hasEOOFull(state) {
      let obj = {};
      state.EOO.map((item, index) => {
        obj[item[0].itemID] =
          item.length === state.hasEOOLineItems[item[0].itemID];
      });
      return obj;
    },
    hasEOOGatedFull(state) {
      let obj = {};
      state.EOO.map((item, index) => {
        obj[item[0].itemID] =
          item.length === state.hasEOOLineItems[item[0].itemID];
      });
      return obj;
    },
    qtyEOOPicked(state) {
      let total = [0, 0];
      state.EOO.map((lineItem, index) => {
        total[index] = lineItem.filter(
          (x) => x.ContainerNo && x.date_pickup,
        ).length;
      });
      return total;
    },
  },
  actions: {
    async loadEquipmentsOnOrder({ state, commit, getters }) {
      let response = await Promise.all([
        ...state.lineItems.map((lineItem) =>
          get_equipment_on_order(lineItem.itemID),
        ),
        ...getters.uniqueDepotLineItems.map((lineItem) =>
          available_container_no({
            status: 1,
            depot: lineItem.Location,
            size: lineItem.sizeDescr,
            cndtn: lineItem.condDescr,
            type: lineItem.typeCode,
          }),
        ),
      ]);
      let eoo = response.splice(0, state.lineItems.length);

      let equipments = [];
      for (let i = 0; i < response.length; i++) {
        equipments.push(response[i].data);
      }
      commit("setEOO", eoo);
      commit("setEquipments", equipments);
    },
    async loadDetails(
      { state, getters, commit, rootGetters, dispatch },
      docNo,
    ) {
      commit("setSpinnerShow", true, { root: true });

      try {
        // let res = await Promise.all([
        //   get_single_order_headers_by_docNo(docNo),
        //   get_single_order_details_by_docNo(docNo),
        //   getCustomFieldByOrder(docNo),
        //   get_release_details(docNo),
        //   get_inventory_qty_summs(),
        //   get_single_credit(docNo),
        // ]);

        let res = await Promise.all([
          get_single_order_headers_by_docNo_docType_inv(docNo),
          get_single_order_details_by_docNo_docType_inv(docNo),
          getCustomFieldByOrder(docNo),
          get_release_details(docNo),
          get_inventory_qty_summs(),
          get_single_credit(docNo),
          get_order_type(docNo),

          dispatch("moduleOrders/getOrdersTerm", {}, { root: true }),
          dispatch("moduleOrders/getOrdersType", {}, { root: true }),
        ]);

        commit(
          "moduleOrders/setDetails",
          { ...res[0][0], ...res[1][0] },
          { root: true },
        );
        commit("setLineItems", res[1]);
        commit("setCustomFields", res[2]);
        commit("setReleaseDetails", res[3]);
        commit("moduleQtySumm/setInventoryQtySumms", res[4].data.mergedData, {
          root: true,
        });
        commit("setCreditData", res[5].message[0]);

        commit("setOrderTypeDetails", res[6].data.data);

        let response = await Promise.all([
          ...state.lineItems.map((lineItem) =>
            get_equipment_on_order(lineItem.itemID),
          ),
          ...getters.uniqueDepotLineItems.map((lineItem) =>
            available_container_no({
              status: 1,
              depot: lineItem.Location,
              size: lineItem.sizeDescr,
              cndtn: lineItem.condDescr,
              type: lineItem.typeCode,
            }),
          ),
        ]);

        let eoo = response.splice(0, state.lineItems.length);

        let equipments = [];
        for (let i = 0; i < response.length; i++) {
          equipments.push(response[i].data);
        }
        let tabDetails = rootGetters["moduleOrders/tabDetails"];
        res = await get_payment_details(tabDetails.id);
        commit("setPaymentDetails", res);
        commit("setEOO", eoo);
        commit("setEquipments", equipments);
        commit("setSpinnerShow", false, { root: true });
      } catch (error) {}
    },

    async refreshAllPanels({ state, commit }, data) {
      const { docNo, id } = data;
      console.log("refreshAllPanels docNo, id", [docNo, id]);

      commit("setSpinnerShow", true, { root: true });

      try {
        // get_single_order_headers_by_docNo(docNo).then((res) => {
        //   commit("moduleOrders/setTabDetails", res[0], { root: true });
        // });

        // get_single_order_details_by_docNo(docNo).then((res) => {
        //   commit("setLineItems", res);
        // });

        get_single_order_headers_by_docNo_docType_inv(docNo).then((res) => {
          commit("moduleOrders/setTabDetails", res[0], { root: true });
        });

        get_single_order_details_by_docNo_docType_inv(docNo).then((res) => {
          commit("setLineItems", res);
        });

        get_payment_details(id).then((res) => {
          commit("setPaymentDetails", res);
        });

        get_release_details(docNo).then((res) => {
          commit("setReleaseDetails", res);
        });

        Promise.all(
          state.lineItems.flatMap((lineItem) => [
            get_equipment_on_order(lineItem.itemID),
            available_container_no({
              status: 1,
              depot: lineItem.Location,
              size: lineItem.sizeDescr,
              cndtn: lineItem.condDescr,
              type: lineItem.typeCode,
            }),
          ]),
        ).then((response) => {
          let eoo = [],
            equipments = [];
          for (let i = 0; i < response.length; i++) {
            if (i % 2 == 0) {
              eoo.push(response[i]);
            } else {
              equipments.push(response[i].data);
            }
          }
          commit("setEOO", eoo);
          commit("setEquipments", equipments);

          commit("setSpinnerShow", false, { root: true });
        });
      } catch (error) {
        console.log(error);
      }
    },
    async updateDetails(
      { state, commit, getters, rootGetters, dispatch },
      { name, index, data },
    ) {
      commit("setSpinnerShowOD", true, { root: true });
      let docNo = rootGetters["moduleOrders/docNo"];
      let tabDetails = rootGetters["moduleOrders/tabDetails"];
      dispatch(
        "moduleOrders/getOrderActivities",
        { order: docNo },
        { root: true },
      );
      await update_order_pipeline({ invoice_id: tabDetails.id });
      // await this.getReleasesCanceled();
      await dispatch("getReleasesCanceled");
      switch (name) {
        case "release":
          setTimeout(async () => {
            try {
              // let res = await Promise.all([
              //   get_release_details(docNo),
              //   get_single_order_headers_by_docNo(docNo),
              //   get_single_order_details_by_docNo(docNo),
              // ]);

              let res = await Promise.all([
                get_release_details(docNo),
                get_single_order_headers_by_docNo_docType_inv(docNo),
                get_single_order_details_by_docNo_docType_inv(docNo),
              ]);

              commit("setReleaseDetails", res[0]);
              commit(
                "moduleOrders/setDetails",
                { ...res[1][0], ...res[2][0] },
                { root: true },
              );

              commit("setLineItems", res[2]);

              commit("setSpinnerShowOD", false, { root: true });
            } catch (error) {
              console.log("error");
              console.log(error);

              commit(
                "setSnackbar",
                {
                  text: `Something went wrong.`,
                  status: "danger",
                  show: "showNav",
                },
                { root: true },
              );
            }
          }, 1000);

          break;
        case "EOO":
          setTimeout(async () => {
            let lineItem = state.lineItems[index];
            let response = await Promise.all([
              get_equipment_on_order(lineItem.itemID),
              available_container_no({
                status: 1,
                depot: lineItem.Location,
                size: lineItem.sizeDescr,
                cndtn: lineItem.condDescr,
                type: lineItem.typeCode,
              }),
            ]);

            let eoo = [],
              equipments = [];
            for (let i = 0; i < response.length; i++) {
              if (i % 2 == 0) {
                eoo.push(response[i]);
              } else {
                equipments.push(response[i].data);
              }
            }

            commit("updateEOO", { index, value: eoo[0] });

            commit("updateEquipments", { index, value: equipments[0] });

            setTimeout(async () => {
              // let res = await Promise.all([
              //   get_single_order_headers_by_docNo(docNo),
              //   get_single_order_details_by_docNo(docNo),
              // ]);

              let res = await Promise.all([
                get_single_order_headers_by_docNo_docType_inv(docNo),
                get_single_order_details_by_docNo_docType_inv(docNo),
                get_single_credit(docNo),
                get_credit_balance(docNo),
              ]);

              console.log(res);

              commit(
                "moduleOrders/setDetails",
                { ...res[0][0], ...res[1][0] },
                { root: true },
              );
              commit("setLineItems", res[1]);
              commit("setCreditData", res[2].message[0]);
              commit("setCreditBalance", res[3].data);
              commit("setSpinnerShowOD", false, { root: true });
            }, 2000);
          }, 1000);

          break;
        case "credit":
          let res = await Promise.all([
            get_single_credit(docNo),
            get_single_order_headers_by_docNo_docType_inv(docNo),
            get_single_order_details_by_docNo_docType_inv(docNo),
            get_equipment_on_order(data.itemID),
            get_credit_balance(docNo),
          ]);

          commit("setCreditData", res[0].message[0]);

          commit(
            "moduleOrders/setDetails",
            { ...res[1][0], ...res[2][0] },
            { root: true },
          );
          commit("setLineItems", res[2]);
          commit("updateEOO", { index, value: res[3] });
          commit("setEOOIndex", index);
          commit("setCreditBalance", res[4].data);
          break;

        default:
          setTimeout(async () => {
            try {
              let res = await Promise.all([
                get_single_order_headers_by_docNo_docType_inv(docNo),
                get_single_order_details_by_docNo_docType_inv(docNo),
                get_single_credit(docNo),
                get_credit_balance(docNo),
              ]);

              commit(
                "moduleOrders/setDetails",
                { ...res[0][0], ...res[1][0] },
                { root: true },
              );
              commit("setLineItems", res[1]);
              commit("setCreditData", res[2].message[0]);
              commit("setCreditBalance", res[3].data);
            } catch (error) {
              console.log("error");
              console.log(error);

              commit(
                "setSnackbar",
                {
                  text: `Something went wrong.`,
                  status: "danger",
                  show: "showNav",
                },
                { root: true },
              );
            }
            commit("setSpinnerShowOD", false, { root: true });
          }, 3000);

          break;
      }
    },

    async getReleaseDetails({ commit }, docNo) {
      commit("setSpinnerShow", true, { root: true });
      let response;
      try {
        response = await get_release_details(docNo);

        commit("setReleaseDetails", response);

        commit(
          "setSnackbar",
          {
            text: "Got payment details",
            status: "success",
            show: "showNav",
          },
          { root: true },
        );
      } catch (error) {
        console.log("error");
        commit(
          "setSnackbar",
          {
            text: `Something went wrong. ${
              error ? error.data.message : "Server problem"
            }`,
            status: "danger",
            show: "showNav",
          },
          { root: true },
        );
        console.log(error);
      }
      commit("setSpinnerShow", false, { root: true });
      return response;
    },

    async getPaymentDetails({ commit }, id) {
      console.log(id);
      commit("setSpinnerShow", true, { root: true });
      let response;
      try {
        response = await get_payment_details(id);

        commit("setPaymentDetails", response);

        commit(
          "setSnackbar",
          {
            text: "Got payment details",
            status: "success",
            show: "showNav",
          },
          { root: true },
        );
      } catch (error) {
        console.log("error");
        commit(
          "setSnackbar",
          {
            text: `Something went wrong. ${
              error ? error.data.message : "Server problem"
            }`,
            status: "danger",
            show: "showNav",
          },
          { root: true },
        );
        console.log(error);
      }
      commit("setSpinnerShow", false, { root: true });
      return response;
    },

    async getOrderDetailByDocNo({ state, commit }, docNo) {
      // commit("setSpinnerShow", true, { root: true });

      try {
        // let res = await get_single_order_details_by_docNo(docNo);
        let res = await get_single_order_details_by_docNo_docType_inv(docNo);
        // commit("setLineItems", []);

        commit("setLineItems", res);
        // commit("moduleOrders/setDetails", res, { root: true });
      } catch (error) {}
      // commit("setSpinnerShow", false, { root: true });
      return;
    },

    async getOrderByDocNo({ state, commit }, docNo) {
      commit("setSpinnerShow", true, { root: true });
      setTimeout(async function () {
        try {
          let res = await get_single_order_headers_by_docNo(docNo);

          commit("moduleOrders/setTabDetails", res[0], { root: true });
          commit("setSpinnerShow", false, { root: true });
        } catch (error) {}
      }, 30000);
    },

    async updateEquipmentOnOrderSingle({ commit }, data) {
      try {
        await Promise.all(
          data.map((value) => {
            update_equipment_on_order_single(value);
          }),
        );
        commit(
          "setSnackbar",
          {
            status: "success",
            text: "Equipment on order saved",
            show: "showNav",
          },
          { root: true },
        );
      } catch (error) {
        console.log("error update_equipment_on_order_single", error);
        commit(
          "setSnackbar",
          {
            text: `Something went wrong. `,
            status: "danger",
            show: "showNav",
          },
          { root: true },
        );
        console.log(error);
      }
      // commit("setSpinnerShow", false, { root: true });
    },

    async updateEquipmentOnOrderBatch({ commit }, data) {
      commit("setSpinnerShow", true, { root: true });
      let response;
      try {
        response = await update_equipment_on_order_batch(data);

        commit(
          "setSnackbar",
          {
            text: response.message,
            status: "Equipment on order saved",
            show: "showNav",
          },
          { root: true },
        );
      } catch (error) {
        console.log("error");
        commit(
          "setSnackbar",
          {
            text: `Something went wrong. ${
              error ? error.data.message : "Server problem"
            }`,
            status: "danger",
            show: "showNav",
          },
          { root: true },
        );
        console.log(error);
      }
      commit("setSpinnerShow", false, { root: true });
      return response;
    },
    async getEquipmentsFiltered({ commit }, data) {
      commit("setSpinnerShow", true, { root: true });

      try {
        let res = await filter_inventory(data, 1, "ALL");
        console.log(res);
        commit("setEquipments", res);
      } catch (error) {}
      commit("setSpinnerShow", false, { root: true });
      return;
    },

    async getEOO({ state, commit }, spinner) {
      if (spinner) commit("setSpinnerShow", true, { root: true });

      try {
        let response = await Promise.all(
          state.lineItems.flatMap((lineItem) => [
            get_equipment_on_order(lineItem.itemID),
            available_container_no({
              status: 1,
              depot: lineItem.Location,
              size: lineItem.sizeDescr,
              cndtn: lineItem.condDescr,
              type: lineItem.typeCode,
            }),
          ]),
        );

        let eoo = [],
          equipments = [];
        for (let i = 0; i < response.length; i++) {
          if (i % 2 == 0) {
            eoo.push(response[i]);
          } else {
            equipments.push(response[i].data);
          }
        }

        if (spinner) commit("setSpinnerShow", false, { root: true });

        commit("setEOO", eoo);
        commit("setEquipments", equipments);
      } catch (error) {}
      commit("setSpinnerShow", false, { root: true });
      return;
    },

    async getInvoiceMetaDetails({ commit }, id) {
      commit("setSpinnerShow", true, { root: true });
      let response;
      try {
        response = await get_invoice_meta_details(id);

        commit("setInvoiceMetaDetails", response.data);

        commit(
          "setSnackbar",
          {
            text: "Got invoice meta details",
            status: "success",
            show: "showNav",
          },
          { root: true },
        );
      } catch (error) {
        console.log("error");
        commit(
          "setSnackbar",
          {
            text: `Something went wrong. ${
              error ? error.data.message : "Server problem"
            }`,
            status: "danger",
            show: "showNav",
          },
          { root: true },
        );
        console.log(error);
      }
      commit("setSpinnerShow", false, { root: true });
      return response;
    },

    async changeQtyLineItemGB({ commit, dispatch, rootGetters }, data) {
      commit("setSpinnerShowOD", true, { root: true });
      let response = null;
      try {
        response = await set_qty_line_item({
          docNo: data.docNo,
          invoice_id: data.invoice_id,
          lineItem: data.lineItem,
        });

        commit("setSpinnerShowOD", false, { root: true });
      } catch (error) {
        console.log(error);
        commit("setSpinnerShowOD", false, { root: true });

        commit(
          "setSnackbar",
          {
            text: `${error.response?.data?.message}`,
            status: "warning",
            show: "showNav",
          },
          { root: true },
        );

        //message
      }
      return;
    },
    async changeQtyLineItem({ commit, dispatch, rootGetters }, data) {
      commit("setSpinnerShowOD", true, { root: true });
      let response = null;
      try {
        await Promise.all([
          change_sent_value_single_order_details({
            docNo: data.docNo,
            id: data.invoice_id,
            sent: "R",
          }),
        ]);

        response = await set_qty_line_item({
          docNo: data.docNo,
          invoice_id: data.invoice_id,
          lineItem: data.lineItem,
        });
        // await dispatch(
        //   "sendEmailServerBos",
        //   {
        //     toEmail: rootGetters["moduleOrders/tabDetails"].custEmails,
        //     subject: `CMA-CGM:: Bill of Sale No: ${data.docNo}`,
        //     BOSNumber: data.docNo,
        //   },
        //   { root: true }
        // );

        await dispatch("updateDetails", {
          name: "credit",
          data: data.lineItem[0],
          index: data.index,
        });

        commit("setSpinnerShowOD", false, { root: true });
      } catch (error) {
        console.log(error);
        commit("setSpinnerShowOD", false, { root: true });

        commit(
          "setSnackbar",
          {
            text: `${error.response?.data?.message}`,
            status: "warning",
            show: "showNav",
          },
          { root: true },
        );

        //message
      }
      return;
    },

    async removeLineItem({ commit, dispatch, rootGetters }, data) {
      commit("setSpinnerShowOD", true, { root: true });
      try {
        await Promise.all([
          change_sent_value_single_order_details({
            docNo: data.docNo,
            id: data.invoice_id,
            sent: "R",
          }),

          await remove_line_item({
            invoice_id: data.invoice_id,
            lineItem: data.lineItem,
          }),
        ]);

        // await dispatch(
        //   "sendEmailServerBos",
        //   {
        //     toEmail: rootGetters["moduleOrders/tabDetails"].custEmails,
        //     subject: `CMA-CGM:: Bill of Sale No: ${data.docNo}`,
        //     BOSNumber: data.docNo,
        //   },
        //   { root: true }
        // );
        // let res = await Promise.all([
        //   get_single_order_headers_by_docNo(data.docNo),
        //   get_single_order_details_by_docNo(data.docNo),
        // ]);

        // let res = await Promise.all([
        //   get_single_order_headers_by_docNo_docType_inv(data.docNo),
        //   get_single_order_details_by_docNo_docType_inv(data.docNo),
        // ]);
        // commit(
        //   "moduleOrders/setDetails",
        //   { ...res[0][0], ...res[1][0] },
        //   { root: true },
        // );
        commit("setSpinnerShowOD", false, { root: true });

        await dispatch("updateDetails", { name: "" });
      } catch (error) {
        console.log(error);
      }
      return;
    },

    resetDetailsPage({ commit }) {
      commit("setLineItems", []);
      commit("setPaymentDetails", []);
      commit("setReleaseDetails", []);
      commit("setEooDetails", []);
      commit("setItemSelected", {});
      commit("setCustomFields", []);
      commit("setEOO", []);
      commit("setEquipments", []);
      commit("setPanelsOpen", []);
      commit("setInfoMessage", null);
      commit("setInvoiceMetaDetails", []);
      commit("moduleOrders/setTabDetails", {}, { root: true });
      commit("moduleOrders/setOrderActivities", [], { root: true });
      commit("moduleOrders/setOrderFiles", [], { root: true });
      commit("setEquipmentMRComments", null);
    },
    async getComments({ commit }, { docRef }) {
      commit("setSpinnerShow", true, { root: true });
      let response;
      try {
        response = await get_comments(docRef);

        commit("setComments", response);

        commit(
          "setSnackbar",
          {
            text: "Got Comments",
            status: "success",
            show: "showNav",
          },
          { root: true },
        );
      } catch (error) {
        // commit(
        //   "setSnackbar",
        //   {
        //     text: `Something went wrong. ${
        //       error ? error.data.message : "Server problem"
        //     }`,
        //     status: "danger",
        //     show: "showNav",
        //   },
        //   { root: true },
        // );
        console.log(error);
      }
      commit("setSpinnerShow", false, { root: true });
      return response;
    },
    async updateComment({ commit }, { docRef, data }) {
      commit("setSpinnerShow", true, { root: true });
      let response;
      try {
        response = await update_comment(data);
        response = await get_comments(docRef);

        commit("setComments", response);

        commit(
          "setSnackbar",
          {
            text: "Comment Updated",
            status: "success",
            show: "showNav",
          },
          { root: true },
        );
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong.`,
            status: "danger",
            show: "showNav",
          },
          { root: true },
        );
        console.log(error);
      }
      commit("setSpinnerShow", false, { root: true });
    },
    async deleteComment({ commit }, { docRef, data }) {
      commit("setSpinnerShow", true, { root: true });
      let response;
      try {
        response = await delete_comment(data);
        response = await get_comments(docRef);

        commit("setComments", response);

        commit(
          "setSnackbar",
          {
            text: "Comment Deleted",
            status: "success",
            show: "showNav",
          },
          { root: true },
        );
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong.`,
            status: "danger",
            show: "showNav",
          },
          { root: true },
        );
        console.log(error);
      }
      commit("setSpinnerShow", false, { root: true });
    },
    async createComment({ commit }, { docRef, data }) {
      commit("setSpinnerShow", true, { root: true });
      let response;
      try {
        response = await create_comment(data);
        response = await get_comments(docRef);

        commit("setComments", response);

        commit(
          "setSnackbar",
          {
            text: "Comment Created",
            status: "success",
            show: "showNav",
          },
          { root: true },
          (response = true),
        );
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong.`,
            status: "danger",
            show: "showNav",
          },
          { root: true },
        );
        console.log(error);
        response = false;
      }
      commit("setSpinnerShow", false, { root: true });
      return response;
    },
    async getNotesViewAccess({ commit }) {
      let response;
      console.log("of her");
      try {
        response = await get_notes_view_acces();

        commit("setViewAccess", response.viewAccess);
      } catch (error) {}
    },
    async createInvoiceMeta({ commit, rootGetters }, data) {
      commit("setSpinnerShow", true, { root: true });
      let response;
      try {
        await create_invoice_meta({
          ...data,
        });

        response = await get_invoice_meta_details(
          rootGetters["moduleOrders/tabDetails"].id,
        );

        commit(
          "moduleOrders/moduleDetails/setInvoiceMetaDetails",
          response.data,
          {
            root: true,
          },
        );

        commit(
          "setSnackbar",
          {
            text: "Invoice saved",
            status: "success",
            show: "showNav",
          },
          { root: true },
        );
      } catch (error) {
        console.log("error");
        console.log(error);

        commit(
          "setSnackbar",
          {
            text: `Something went wrong.`,
            status: "danger",
            show: "showNav",
          },
          { root: true },
        );
      }
      commit("setSpinnerShow", false, { root: true });
      return;
    },
    async updateInvoiceMeta({ commit, getters, rootGetters }, data) {
      commit("setSpinnerShow", true, { root: true });
      let response;
      try {
        await update_invoice_meta({
          ...data,
          invoice_meta_id: data.id,
        });

        response = await get_invoice_meta_details(
          rootGetters["moduleOrders/tabDetails"].id,
        );

        commit(
          "moduleOrders/moduleDetails/setInvoiceMetaDetails",
          response.data,
          {
            root: true,
          },
        );

        commit(
          "setSnackbar",
          {
            text: "Invoice meta updated",
            status: "success",
            show: "showNav",
          },
          { root: true },
        );
      } catch (error) {
        console.log("error");
        console.log(error);

        commit(
          "setSnackbar",
          {
            text: `Something went wrong.`,
            status: "danger",
            show: "showNav",
          },
          { root: true },
        );
      }
      commit("setSpinnerShow", false, { root: true });
      return;
    },
    async deleteInvoiceMeta({ commit, getters, rootGetters, dispatch }, data) {
      commit("setSpinnerShow", true, { root: true });
      let response;

      try {
        await delete_invoice_meta({
          ...data,
          invoice_meta_id: data.id,
        });

        response = await get_invoice_meta_details(
          rootGetters["moduleOrders/tabDetails"].id,
        );

        commit(
          "moduleOrders/moduleDetails/setInvoiceMetaDetails",
          response.data,
          {
            root: true,
          },
        );

        commit(
          "setSnackbar",
          {
            text: "Invoice meta deleted",
            status: "success",
            show: "showNav",
          },
          { root: true },
        );
      } catch (error) {
        console.log("error");
        console.log(error);

        commit(
          "setSnackbar",
          {
            text: `Something went wrong.`,
            status: "danger",
            show: "showNav",
          },
          { root: true },
        );
      }
      commit("setSpinnerShow", false, { root: true });
      return;
    },
    async printViewBOS({ commit, getters, rootGetters, dispatch }, saldate) {
      const token = localStorage.getItem("access_token");
      let { docNo, id, bill_of_sale } = rootGetters["moduleOrders/tabDetails"];
      const url = process.env.VUE_APP_SCRIPTCASE_API_PATH;
      const url_new = process.env.VUE_APP_FILES_URL;
      commit("setSpinnerShowOD", true, { root: true });

      const bos_options_url = `rptVendToCustBOS_v2/?v_invoiceID=${id}&v_invoiceNO=${docNo}&bos_option=${bill_of_sale}&saldate=${saldate}&token=${token}`;
      const bos_options_new_url = `BOS?invoiceID=${docNo}&token=${token}`;

      window.open(`${url_new}/${bos_options_new_url}`, "_blank");

      try {
        remote_upload_pdf_to_order({
          url: `https://nereus-inf-pdf-gen-service-development-vjuqlq6gsq-uw.a.run.app/?url=${url_new}/${bos_options_new_url}`,

          docType: "SALEINVOICE",
          category: "BOS",
          sourceType: "FILEATTACH",
          sourceid: docNo,
          permissions: "write,read",
          organization_id: null,
        });
        await Promise.all([
          trans_log_order({
            order_id: id,
            doc_table: "invoices",
            trans_desc: "Bill of Sale print/view view opened",
            application: "PDF Gen Service",
            trans_action: "Print",
            old_value: "",
            new_value: "",
          }),

          change_sent_value_single_order_details({
            docNo: docNo,
            id,
            sent: "Y",
          }),
        ]);
        commit("setSpinnerShowOD", false, { root: true });

        await dispatch("updateDetails", { name: "BillofSale" });
        commit("setInfoMessage", null);
      } catch (error) {
        commit("setSpinnerShowOD", false, { root: true });
        await dispatch("updateDetails", { name: "BillofSale" });
        commit("setInfoMessage", null);
        console.log(error);
      }

      return;
    },
    async deleteEOO({ commit }, data) {
      commit("setSpinnerShow", true, { root: true });

      try {
        let response = await delete_EOO(data);

        commit(
          "setSnackbar",
          {
            text: response.message,
            status: "success",
            show: "showNav",
          },
          { root: true },
        );
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong.`,
            status: "danger",
            show: "showNav",
          },
          { root: true },
        );
      }
      commit("setSpinnerShow", false, { root: true });
    },
    async getCustBalanceCredit({ commit }, { custID }) {
      let response;
      try {
        commit("setCustomerAccountBalance", 0);
        response = await get_cust_balance_credit(custID);

        commit("setCustomerAccountBalance", response.data.balance);

        return response;
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong.`,
            status: "danger",
            show: "showNav",
          },
          { root: true },
        );
      }
      commit("setSpinnerShow", false, { root: true });
    },
    async getReleasesCanceled({ commit, rootGetters }) {
      let response;
      try {
        let docNo = rootGetters["moduleOrders/docNo"];

        response = await get_releases_canceled(docNo);
        commit("setReleaseCanceled", response.data);
        return response;
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong.`,
            status: "danger",
            show: "showNav",
          },
          { root: true },
        );
      }
      commit("setSpinnerShow", false, { root: true });
    },
    async getCreditBalance({ commit, rootGetters }) {
      let response;
      try {
        let docNo = rootGetters["moduleOrders/docNo"];

        response = await get_credit_balance(docNo);
        commit("setCreditBalance", response.data);
      } catch (error) {
        console.log(error);
      }
      return response;
    },
    async getMRCommentsOnContainer({ commit }, { EquipmentID }) {
      let response;
      try {
        response = await get_container_comment({ EquipmentID });

        commit("setEquipmentMRComments", response[0].MRComment);
        return response;
      } catch (error) {
        console.log("get mr comments error", error);
        // commit(
        //   "setSnackbar",
        //   {
        //     text: `Something went wrong.`,
        //     status: "danger",
        //     show: "showNav",
        //   },
        //   { root: true },
        // );
      }
      commit("setSpinnerShow", false, { root: true });
    },
    async processContainerMRComment({ commit }, data) {
      commit("setSpinnerShow", true, { root: true });
      let response;
      try {
        if (data.MRComments === "") {
          response = await delete_container_comment(data).catch((err) => {
            throw new Error(err);
          });
        } else {
          console.log("update comment");
          response = await update_container_comment(data).catch((err) => {
            throw new Error(err);
          });
        }
        commit(
          "setSnackbar",
          {
            text: `Processed MR Comment successfully.`,
            status: "success",
            show: "showNav",
          },
          { root: true },
        );
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong. ${
              error ? error.data.message ?? error : "Server problem"
            }`,
            status: "danger",
            show: "showNav",
          },
          { root: true },
        );
      }
      commit("setSpinnerShow", false, { root: true });
      return response;
    },
    async updateDisplayMRCommentSetting({ commit }, data) {
      commit("setSpinnerShow", true, { root: true });
      let response;
      try {
        response = await update_mr_comment_bos_display(data).catch((err) => {
          throw new Error(err);
        });

        commit(
          "setSnackbar",
          {
            text: `Updated BOS MR Comment display setting.`,
            status: "success",
            show: "showNav",
          },
          { root: true },
        );
      } catch (error) {
        console.log(error);
        commit(
          "setSnackbar",
          {
            text: `Something went wrong. ${
              error ? error.data.message ?? error : "Server problem"
            }`,
            status: "danger",
            show: "showNav",
          },
          { root: true },
        );
      }
      commit("setSpinnerShow", false, { root: true });
      return response;
    },
    async saveAddedLineItems({ commit, dispatch, rootGetters }, data) {
      commit("setSpinnerShow", true, { root: true });

      try {
        await save_added_line_items(data);
        // await dispatch(
        //   "sendEmailServerBos",
        //   {
        //     toEmail: rootGetters["moduleOrders/tabDetails"].custEmails,
        //     subject: `CMA-CGM:: Bill of Sale No: ${data.DocNo}`,
        //     BOSNumber: data.DocNo,
        //   },
        //   { root: true }
        // );
        let res = await Promise.all([
          get_single_order_headers_by_docNo_docType_inv(data.DocNo),
          get_single_order_details_by_docNo_docType_inv(data.DocNo),
        ]);

        commit(
          "moduleOrders/setDetails",
          { ...res[0][0], ...res[1][0] },
          { root: true },
        );
        commit("setLineItems", res[1]);

        // await dispatch("getOrderDetailByDocNo", data.DocNo);
        await dispatch("loadEquipmentsOnOrder");
        commit(
          "setSnackbar",
          {
            text: `New line items added`,
            status: "success",
            show: "showNav",
          },
          { root: true },
        );
      } catch (error) {
        console.log(error);
        commit(
          "setSnackbar",
          {
            text: `Something went wrong.`,
            status: "danger",
            show: "showNav",
          },
          { root: true },
        );
      }
      commit("setSpinnerShow", false, { root: true });

      return;
    },
    async deletePayment({ commit, rootGetters }, { payment_id }) {
      commit("setSpinnerShow", true, { root: true });
      try {
        let tabDetails = rootGetters["moduleOrders/tabDetails"];

        await delete_payment(tabDetails.invoice_no, payment_id);
        let res = await get_payment_details(tabDetails.id);
        commit("setPaymentDetails", res);
      } catch (error) {
        console.log(error);
        commit(
          "setSnackbar",
          {
            text: `Something went wrong.`,
            status: "danger",
            show: "showNav",
          },
          { root: true },
        );
      }
      commit("setSpinnerShow", false, { root: true });
      return;
    },
    async sendReleases({ commit, dispatch, rootGetters }, data) {
      commit("setSpinnerShow", true, { root: true });
      try {
        await send_releases(data);
        let res = await get_release_details(
          rootGetters["moduleOrders/tabDetails"].docNo,
        );
        commit("setReleaseDetails", res);

        commit(
          "setSnackbar",
          {
            text: "Success. Releases sent.",
            status: "success",
            show: "showNav",
          },
          { root: true },
        );
      } catch (error) {
        console.log(error);
        commit(
          "setSnackbar",
          {
            text: `Something went wrong.`,
            status: "danger",
            show: "showNav",
          },
          { root: true },
        );
      }
      commit("setSpinnerShow", false, { root: true });
      return;
    },
    async createDVContainer({ commit, rootGetters, dispatch }, data) {
      commit("setSpinnerShow", true, { root: true });
      let tabDetails = rootGetters["moduleOrders/tabDetails"];

      try {
        await create_dv_container(data);

        commit("setDVContainer", null);
        const res = await Promise.all([
          get_payment_details(tabDetails.id),
          dispatch("getOrderDetailByDocNo", tabDetails.docNo),
        ]);
        await dispatch("loadEquipmentsOnOrder");
        commit("setPaymentDetails", res[0]);
        commit(
          "setSnackbar",
          {
            text: "Success. DV created.",
            status: "success",
            show: "showNav",
          },
          { root: true },
        );
      } catch (error) {
        console.log(error);
        commit(
          "setSnackbar",
          {
            text: `Something went wrong.`,
            status: "danger",
            show: "showNav",
          },
          { root: true },
        );
      }
      commit("setSpinnerShow", false, { root: true });
      return;
    },
  },
};
