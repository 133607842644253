import Vue from "vue";
import Vuex from "vuex";
import { DateTime } from "luxon";
import { orderBy } from "natural-orderby";
import {
  get_saved_filters,
  get_orgs,
  filter_inventory,
  get_inventory_paginated,
  delete_filter,
  create_filter,
  update_saved_filter,
  create_buyer_party,
  get_container_statuses,
  create_container_status,
  update_toggle_container_status,
  get_inventory_qty_summs,
  get_depot_terminations,
  create_depot_termination,
  update_toggle_depot_termination,
  get_inventory_allocations,
  create_inventory_allocation,
  update_toggle_inventory_allocation,
  get_countries,
  get_depots_all,
  send_invite_emails,
  get_unique_link_invitation,
  sent_serve_bos_email,
} from "@/helpers/api/indexv2.js";
import {
  validationsItem,
  updateItem,
  createItem,
} from "@/helpers/inventory.js";

import {
  createVendorDepotManage,
  updateVendorDepotManage,
} from "@/helpers/api/VendorDepots/index.js";

import {
  makePublicFile,
  deleteFile,
  uploadFile,
  uploadMasterContract,
  getSignedURL,
  getListOfFilesByOrganization,
} from "@/helpers/api/Organizations/index.js";

import {
  getListOfCustomFieldsForOrganization,
  createCustomFieldForOrganization,
  deleteCustomField,
} from "@/helpers/api/CustomFields/index.js";

import { DATES } from "@/helpers/mocks/dates.js";
import { getFilesBySourceID } from "@/helpers/api/Organizations/index.js";
import moduleOrders from "@/store/modules/orders/index.js";
import moduleOrganizations from "@/store/modules/organizations/index.js";
import moduleQtySumm from "@/store/modules/inventoryQtySumm/index.js";
import moduleDepotManage from "@/store/modules/depotManage/index.js";
import moduleReports from "@/store/modules/reports/index.js";
import moduleAuctions from "@/store/modules/auctions/index.js";

import moduleInventoryWarnings from "@/store/modules/inventoryWarnings/index.js";
import customerDistribution from "@/store/modules/customerDistribution/index.js";
import moduleEmails from "@/store/modules/emails/index.js";
import moduleSQLReports from "@/store/modules/SQLReports/index.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    test: [
      { id: 1, name: "John" },
      { id: 2, name: "Jane" },
    ],
    snackbars: [],
    user: {},
    inventoryDataFiltered: [],
    inventorySearchString: "",
    inventorySearchFlag: false,
    inventory: [],
    organizations: [],
    snackbar: {
      text: "",
      showNav: false,
      showLog: false,
      color: "",
    },
    toggleSideBarMain: true,
    spinnerShow: false,
    spinnerShowOD: false,
    inventoryFilteredColumnData: [],
    inventoryFilteredColumnFlag: false,
    savedFilters: [],
    headerGridValues: [],
    resetHeader: false,
    tableIsLoading: false,
    totalItems: 0,
    totalPages: 0,
    optionsTable: {
      page: 1,
      itemsPerPage: 50,
    },
    flagSort: false,
    userInfo: {},
    changedItems: [],
    // newItems: [],
    filters: [],
    containerStatuses: [],
    depotManage: [],
    orderFiles: [],
    list_of_files: [],
    listOfCustomFieldOrganization: [],
    releaseDeleted: null
  },
  mutations: {
    set_user(state, payload) {
      state.user = payload;
    },
    setInventoryDataFiltered(state, newState) {
      Vue.set(state, "inventoryDataFiltered", newState);
    },
    setInventorySearchString(state, newState) {
      state.inventorySearchString = newState;
    },
    setInventory(state, newState) {
      let items = newState.map((value) => Object.assign({}, value));
      items.map((item) => {
        // dates
        DATES.forEach((date) => {
          if (item[date]) {
            item[date] = DateTime.fromSQL(item[date], {
              zone: "America/Phoenix",
            }).toSQL();
          }
        });
      });
      Vue.set(state, "inventory", items);
    },
    setOrganizations(state, newState) {
      Vue.set(state, "organizations", newState);
    },
    setToggleSideBarMain(state, newState) {
      Vue.set(state, "toggleSideBarMain", newState);
    },
    setSpinnerShow(state, newState) {
      Vue.set(state, "spinnerShow", newState);
    },

    setSpinnerShowOD(state, newState) {
      Vue.set(state, "spinnerShowOD", newState);
    },

    showSnackbar(state, value) {
      state.snackbars.push(value);
    },
    hideAllSnackbar(state, value) {
      state.snackbars = [];
    },
    setSnackbar(state, newState) {
      switch (newState.status) {
        case "danger":
          newState["color"] = "red darken-2";
          break;
        case "success":
          newState["color"] = "success darken-2";
          break;
        case "warning":
          newState["color"] = "warning darken-2";
          break;
      }
      let newValue = {
        text: newState.text,
        color: newState.color,
        timeout: newState.timeout,
      };

      newValue.show = true;
      state.snackbars.push(newValue);
    },

    hideSnackbar(state, index) {
      state.snackbars[index].show = false;
    },

    setSnackbarOLD(state, newState) {
      switch (newState.status) {
        case "danger":
          newState["color"] = "red darken-2";
          break;
        case "success":
          newState["color"] = "success darken-2";
          break;
      }
      let newValue = {
        text: newState.text,
        color: newState.color,
        timeout: newState.timeout,
      };

      newValue[`${newState.show}`] = true;
      state.snackbar = newValue;
    },
    deleteItemInventory(state, index) {
      Vue.delete(state.inventory, index);
    },
    closeSnackbar(state, newState) {
      Vue.set(state, "snackbar", newState);
    },
    setInventoryFilteredColumnData(state, newState) {
      state.inventoryFilteredColumnData = newState;
    },
    setInventoryFilteredColumnFlag(state, newState) {
      state.inventoryFilteredColumnFlag = newState;
    },
    setSavedFilters(state, newState) {
      state.savedFilters = [...newState];
    },
    setHeaderGridValues(state, newState) {
      state.headerGridValues = newState;
    },
    setResetHeader(state, newState) {
      state.resetHeader = newState;
    },
    setTableIsLoading(state, newState) {
      state.tableIsLoading = newState;
      // if (state.tableIsLoading) state.inventoryDataFiltered = [];
    },
    setOptionsTable(state, newState) {
      state.optionsTable = newState;
    },
    setTotalItems(state, newState) {
      state.totalItems = newState;
    },
    setTotalPages(state, newState) {
      state.totalPages = newState;
    },
    setInventorySearchFlag(state, newState) {
      state.inventorySearchFlag = newState;
    },
    setFlagSort(state, newState) {
      state.flagSort = newState;
    },
    setUserInfo(state, newState) {
      state.userInfo = newState;
    },
    setChangedItems(state, newState) {
      state.changedItems = newState;
    },
    addChangedItem(state, id) {
      state.changedItems.push(id);
    },
    removeChangeItem(state, index) {
      state.changedItems.splice(index, 1);
    },
    setItemsPerPage(state, newState) {
      state.optionsTable.itemsPerPage = newState;
    },
    setPage(state, newState) {
      state.optionsTable.page = newState;
    },
    addFilter(state, newState) {
      state.filters.push(newState);
    },
    setContainerStatuses(state, newState) {
      Vue.set(state, "containerStatuses", newState);
      //console.log("setContainer state: ", state);
    },
    setInventoryQtySumms(state, newState) {
      Vue.set(state, "inventoryQtySumms", newState);
      //console.log("setInventoryQtySumm state: ", state);
    },
    setDepotTerminations(state, newState) {
      Vue.set(state, "depotTerminations", newState);
      //console.log("setDepotTerminations state: ", state);
    },
    setInventoryAllocations(state, newState) {
      Vue.set(state, "inventoryAllocations", newState);
      //console.log("setDepotTerminations state: ", state);
    },
    setCountries(state, newState) {
      Vue.set(state, "countries", newState);
      //console.log("setDepotTerminations state: ", state);
    },
    setDepotManage(state, newState) {
      state.depotManage = newState;
    },
    setContactDepotManage(state, { index, newState }) {
      Vue.set(state.depotManage[index], "contacts", newState);
    },
    setOrderFiles(state, newState) {
      state.orderFiles = newState;
    },
    setListOfFiles(state, newState) {
      state.list_of_files = newState;
    },
    setListOfCustomFieldOrganization(state, newState) {
      state.listOfCustomFieldOrganization = newState;
    },
    setReleaseDeleted(state, newState) {
      state.releaseDeleted = newState;
    }
  },
  getters: {
    getTestById: (state) => {
      return state.test[0];
    },
    getUserInfo: (state) => () => state.userInfo,
    getLastSavedFilter: (state) => {
      let lastValue = state.savedFilters[state.savedFilters.length - 1];
      let info = { id: lastValue.id, name: lastValue.name };
      return info;
    },
    getInvetorySearchString: (state) => () => state.inventorySearchString,
    getIventoryDataFiltered: (state) => () => state.inventoryDataFiltered,
    getSpinnerShow: (state) => () => state.spinnerShow,
    getSpinnerShowOD: (state) => () => state.spinnerShowOD,
    getInventoryFormatData: (state) => {
      let items = state.inventory.map((dataInventory) => {
        let item = Object.assign({}, dataInventory);

        //id has to be string
        item.id = `${item.id}`;
        //price
        item.Price = item.Price ? `${Number(item.Price).toFixed(2)}` : null;

        //dates
        DATES.forEach((date) => {
          if (item[date]) {
            item[date] = DateTime.fromSQL(item[date])
              .toLocal()
              .toFormat("LL/dd/yyyy");
          }
        });

        //status
        switch (item["Status"]) {
          case "1":
            item["Status"] = "SELL";
            break;
          case "2":
            item["Status"] = "ALLOCATE";
            break;
          case "3":
            item["Status"] = "SOLD";
            break;
          case "4":
            item["Status"] = "EXPIRE";
          case "5":
            item["Status"] = "INBOUND";
            break;
          case "6":
            item["Status"] = "IN-CY";
            break;
          case "7":
            item["Status"] = "OUT";
            break;
        }
        return item;
      });
      return items;
    },
    getPage: (state) => state.optionsTable.page,
    getItemsPerPage: (state) => state.optionsTable.itemsPerPage,
    getUserType(state) {
      return state.user.organization_type;
    },
  },
  actions: {
    sortColumn({ state, commit, dispatch }, { sortBy, sortDesc }) {
      let sortData = [];

      if (sortBy.length) {
        sortData.push({
          column: { value: sortBy[0] },
          order: { title: sortDesc[0] ? "Z to A" : "A to Z" },
        });
        commit("setFlagSort", true);
        dispatch("sortInventoryDataFiltered", { sort: sortData });
      } else {
        if (state.flagSort) {
          sortData.push({
            column: { value: "id" },
            order: { title: "A to Z" },
          });
          dispatch("sortInventoryDataFiltered", { sort: sortData });
        }
      }
    },
    sortInventoryDataFiltered({ state, commit }, { sort }) {
      let dataFields = sort.map((value) => {
        return value.column.value;
      });

      let dataOrders = sort.map((value) => {
        if (value.order.title === "A to Z") return "asc";
        else return "desc";
      });

      const sortedData = orderBy(
        state.inventoryDataFiltered,
        sort.length ? dataFields : ["id"],
        sort.length ? dataOrders : ["asc"]
      );

      commit("setInventory", sortedData);
      commit("setInventoryDataFiltered", sortedData);
    },
    async filteredList({ commit, state, getters }, filters) {
      commit("setSpinnerShow", true);
      commit("setTableIsLoading", true);
      let dataFilters = [];
      if (
        state.inventorySearchString &&
        state.inventorySearchString.length &&
        state.inventorySearchFlag
      ) {
        dataFilters.push({
          operator: "SEARCH",
          values: [],
        });
        state.headerGridValues.map((field) => {
          if (field.value !== "All") {
            if (DATES.includes(field.value)) {
              let date = DateTime.fromFormat(
                state.inventorySearchString,
                "MM/dd/yyyy"
              );

              date = date.setZone("America/Phoenix", {
                includeZone: false,
              });
              let parseDate1 = date.toFormat("yyyy-LL-dd HH-mm-ss");
              let parseDate2 = date
                .plus({ days: 1 })
                .toFormat("yyyy-LL-dd HH-mm-ss");

              dataFilters[0].values.push({
                field: field.value,
                operator: "OR",
                parseDate1,
                parseDate2,
                value: state.inventorySearchString,
                title: field.title,
              });
            } else {
              dataFilters[0].values.push({
                field: field.value,
                operator: "OR",
                value: state.inventorySearchString,
                title: field.title,
              });
            }
          }
        });
      }
      if (state.inventoryFilteredColumnFlag)
        dataFilters = [...dataFilters, ...filters];
      try {
        let { data, pass, info } = await filter_inventory(
          dataFilters,
          state.inventorySearchFlag || state.inventoryFilteredColumnFlag
            ? 1
            : getters.getPage,
          getters.getItemsPerPage
        );
        if (pass) {
          let items = data;
          let infoData = info;
          commit("setFlagSort", false);
          commit("setTotalItems", infoData.totalItems);
          commit("setTotalPages", infoData.totalPages);
          commit(
            "setInventory",
            items.map((item) => Object.assign({}, item))
          );

          commit(
            "setInventoryDataFiltered",
            getters.getInventoryFormatData.map((item) =>
              Object.assign({}, item)
            )
          );
          commit("setSnackbar", {
            show: "showNav",
            text: "Data loaded",
            status: "success",
          });
        }
      } catch (error) {
        commit("setSnackbar", {
          text: "No data got. Server problem",
          status: "danger",
          show: "showNav",
        });
        console.log(error);
      }
      commit("setSpinnerShow", false);
      commit("setTableIsLoading", false);
    },
    async getOrganizations({ commit }) {
      try {
        let { data } = await get_orgs();
        commit("setOrganizations", data);
      } catch (error) {
        console.log(error);
      }
    },
    async getInventory({ state, getters, commit }) {
      commit("setTableIsLoading", true);
      try {
        let { data } = await get_inventory_paginated(
          getters.getPage,
          getters.getItemsPerPage
        );
        if (data) {
          let items = data.data;
          let info = data.info;
          commit("setTotalItems", info.totalItems);
          commit(
            "setInventory",
            items.map((item) => Object.assign({}, item))
          );
          commit(
            "setInventoryDataFiltered",
            getters.getInventoryFormatData.map((item) =>
              Object.assign({}, item)
            )
          );
          commit("setSnackbar", {
            text: "Data loaded",
            status: "success",
            show: "showNav",
          });
        } else throw new Error();
      } catch (error) {
        commit("setSnackbar", {
          text: "No data got. Server problem",
          status: "danger",
          show: "showNav",
        });
        console.log(error);
      }
      commit("setTableIsLoading", false);
    },
    async getSavedFiltered({ commit }) {
      try {
        let response = await get_saved_filters();

        if (response && response.pass) {
          commit("setSavedFilters", [...response.data]);
        }
      } catch (error) {
        console.error(error);
      }
    },
    async updateDataTable({ state, commit, dispatch }, values) {
      if (values && values.key) commit(`${values.key}`, values.value);

      dispatch("moduleInventory/getFlagsInstructions");

      state.inventorySearchFlag || state.inventoryFilteredColumnFlag
        ? await dispatch("filteredList", values.filters)
        : await dispatch("filteredList", []);

      commit("setChangedItems", []);
    },
    async dispatchDeleteFilter({ commit, dispatch }, id) {
      commit("setSpinnerShow", true);

      try {
        let { message } = await delete_filter({ id });

        dispatch("getSavedFiltered");
        commit("setSnackbar", {
          text: message,
          status: "success",
          show: "showNav",
        });
      } catch (error) {
        commit("setSnackbar", {
          text: error.message,
          status: "danger",
          show: "showNav",
        });
        console.log(error);
      }
      commit("setSpinnerShow", false);
    },
    async saveFilterData({ commit, dispatch }, { option, name, filters, id }) {
      if (filters.length) {
        commit("setSpinnerShow", true);

        try {
          option === 0
            ? await create_filter({
                name,
                filters,
              })
            : await update_saved_filter({
                id,
                name,
                filters,
              });

          commit("setSnackbar", {
            text:
              option === 0
                ? "Success: Filter created"
                : "Success: Filter Updated",
            status: "success",
            show: "showNav",
          });

          await dispatch("getSavedFiltered");
          commit("setInventoryFilteredColumnFlag", true);
          commit("setSpinnerShow", false);
          return true;
        } catch (error) {
          commit("setSnackbar", {
            text: error.message,
            status: "danger",
            show: "showNav",
          });
          commit("setSpinnerShow", false);
          return false;
        }
      } else
        commit("setSnackbar", {
          text: "There is not filters values to save",
          show: "showNav",
          status: "info",
        });
    },
    async save({ state, commit, dispatch }, { items }) {
      commit("setSpinnerShow", true);
      if (!state.changedItems.length) {
        commit("setSnackbar", {
          text: `No changes on data`,
          status: "info",
          show: "showNav",
        });
      } else
        for (let id of state.changedItems) {
          let index = items.findIndex((obj) => obj.id === id);
          let dataValidated = validationsItem(items[index]);
          // let newItem = newItems.includes(id);
          if (dataValidated !== false) {
            dataValidated.DateCreated = "";
            dataValidated.OutDate = "";
            dataValidated.BuyerParty = null;
            dataValidated.BuyerPartyId = null;
            dataValidated.allocated_to_entity = null;

            console.log("dataValidated", dataValidated);

            try {
              let response;

              if (items[index].newItem) {
                // adding an item
                response = await createItem(dataValidated, index);
              } else {
                // updating an item
                response = await updateItem(
                  state.inventory[index],
                  dataValidated
                );
              }
              commit("setSnackbar", {
                text: response,
                status: "success",
                show: "showNav",
              });
            } catch (error) {
              console.log(error);
              commit("setSnackbar", {
                text: error,
                status: "danger",
                timeout: "7000",
                show: "showNav",
              });
            }
          } else if (items[index].newItem) {
            commit("setSnackbar", {
              text: `Invalid Data. Item not created ${items[index].EquipmentID}`,
              status: "danger",
              timeout: "7000",
              show: "showNav",
            });
          } else {
            commit("setSnackbar", {
              text: `Invalid Data. ${items[index].EquipmentID}`,
              status: "danger",
              timeout: "7000",
              show: "showNav",
            });
          }
        }

      commit("setSpinnerShow", false);
    },
    dispatchSearchEvent({ state, commit, dispatch }, { flag, value }) {
      commit("setInventorySearchFlag", flag);
      commit("setInventorySearchString", value);
      dispatch("updateDataTable", { filters: state.filters });
    },
    async saveOrg({ commit }, { externalName }) {
      commit("setSpinnerShow", true);
      let response;
      try {
        response = await create_buyer_party({
          externalName,
        });
      } catch (error) {
        console.log(error);
      }
      if (response && response.pass) {
        await dispatch("getOrganizations");
        commit("setSnackbar", {
          text: response.msg,
          status: "success",
          show: "showNav",
        });
      } else {
        commit("setSnackbar", {
          text: response.msg,
          status: "danger",
          show: "showNav",
        });
      }
      commit("setSpinnerShow", false);
    },
    createHeaderGrid({ commit }, header) {
      commit("setHeaderGridValues", [...header]);
      localStorage.setItem("header", JSON.stringify(header));
    },
    async getContainerStatuses({ commit }) {
      commit("setSpinnerShow", true);

      try {
        let { data } = await get_container_statuses();
        commit("setContainerStatuses", data);
        commit("setSnackbar", {
          text: "Container Statuses fetched!",
          status: "success",
          show: "showNav",
        });
      } catch (error) {
        console.log(error);
      }
      commit("setSpinnerShow", false);
    },
    async saveContainerStatus({ commit, dispatch }, { flagNew, data }) {
      let response;
      console.log(flagNew, data);
      commit("setSpinnerShow", true);
      try {
        response = flagNew.new
          ? await create_container_status(data)
          : await update_toggle_container_status(data);
        console.log("index saveContainerStatus response", response);
        if (response.pass) {
          commit("setSnackbar", {
            text: response.message,
            status: "success",
            show: "showNav",
            timeout: 1000,
          });
          setTimeout(async () => {
            await dispatch("getContainerStatuses");
          }, 1000);
        } else {
          commit("setSnackbar", {
            text: response.message,
            status: "danger",
            show: "showNav",
          });
        }
      } catch (error) {
        console.log(error);
        commit("setSnackbar", {
          text: error.message,
          status: "danger",
          show: "showNav",
        });
      }
      commit("setSpinnerShow", false);
    },
    async getInventoryQtySumms({ commit }) {
      commit("setSpinnerShow", true);

      try {
        let { data } = await get_inventory_qty_summs();
        commit("setInventoryQtySumms", data.mergedData);
        commit("setSnackbar", {
          text: "Inventory Summaries fetched!",
          status: "success",
          show: "showNav",
        });
      } catch (error) {
        console.log(error);
      }
      commit("setSpinnerShow", false);
    },
    async getDepotTerminations({ commit }) {
      commit("setSpinnerShow", true);

      try {
        let { data } = await get_depot_terminations();

        let depotTerminationData = [];
        data.map((depotTermination) => {
          // console.log("watch depotTermination", depotTermination);

          try {
            if (depotTermination.hasOwnProperty("EffectiveFrom")) {
              const dateServer = new DateTime.fromFormat(
                depotTermination["EffectiveFrom"],
                "yyyy-MM-dd HH:mm:ss",
                {
                  zone: "America/Phoenix",
                }
              );

              let local = dateServer.setZone("local", { includeZone: false });

              depotTermination["EffectiveFrom"] = local.toFormat("yyyy-LL-dd");
            }

            if (depotTermination.hasOwnProperty("EffectiveTo")) {
              const dateServer = new DateTime.fromFormat(
                depotTermination["EffectiveTo"],
                "yyyy-MM-dd HH:mm:ss",
                {
                  zone: "America/Phoenix",
                }
              );

              let local = dateServer.setZone("local", { includeZone: false });

              depotTermination["EffectiveTo"] = local.toFormat("yyyy-LL-dd");
            }
          } catch (error) {
            // depotTermination["EffectiveFrom"] = depotTermination["EffectiveFrom"];
            // depotTermination["EffectiveTo"] = depotTermination["EffectiveTo"];
            // console.log("watch depotTerminations ERROR", error);
          }

          depotTerminationData = [
            ...depotTerminationData,
            Object.assign({}, depotTermination),
          ];
        });

        commit("setDepotTerminations", data);
        commit("setSnackbar", {
          text: "Depot Terminations fetched!",
          status: "success",
          show: "showNav",
        });
      } catch (error) {
        console.log(error);
      }
      commit("setSpinnerShow", false);
    },

    async saveDepotTermination({ commit, dispatch }, { flagNew, data }) {
      let response;
      console.log(flagNew, data);
      commit("setSpinnerShow", true);

      try {
        response = flagNew.new
          ? await create_depot_termination(data)
          : await update_toggle_depot_termination(data);
        console.log("index saveDepotTermination response", response);

        if (response.pass) {
          commit("setSnackbar", {
            text: response.message,
            status: "success",
            show: "showNav",
            timeout: 1000,
          });
          setTimeout(async () => {
            await dispatch("getDepotTerminations");
          }, 1000);
        } else {
          commit("setSnackbar", {
            text: response.message,
            status: "danger",
            show: "showNav",
          });
        }
      } catch (error) {
        console.log(error);
        commit("setSnackbar", {
          text: error.message,
          status: "danger",
          show: "showNav",
        });
      }

      commit("setSpinnerShow", false);
    },
    async getAllManageDepot({ commit }) {
      commit("setSpinnerShow", true);
      try {
        let { data } = await get_depots_all();
        commit("setDepotManage", data);
      } catch (error) {
        commit("setSnackbar", {
          text: "Something went wrong getting all depot data.",
          status: "danger",
          show: "showNav",
        });
      }
      commit("setSpinnerShow", false);
    },

    async savedepotManage({ commit, dispatch }, { flagNew, data, statusData }) {
      let response;
      commit("setSpinnerShow", true);
      if (flagNew) {
        try {
          response = await createVendorDepotManage({
            ...data,
          });
          
          if (response.pass) {
            commit("setSnackbar", {
              text: response.message,
              status: "success",
              show: "showNav",
              timeout: 1000,
            });
          } else {
            commit("setSnackbar", {
              text: "Something went wrong",
              status: "danger",
              show: "showNav",
            });
          }
        } catch (error) {
          commit("setSnackbar", {
            text: error.message,
            status: "danger",
            show: "showNav",
          });
          // return error;
        }
      } else {
        try {
          response = await updateVendorDepotManage(data);
          // await dispatch(
          //   "moduleDepotManage/updateDepotDate",
          //   {
          //     data: {
          //       DepotId: data.DepotId,
          //       date_from: statusData.EffectiveFrom,
          //       date_to: statusData.EffectiveTo,
          //     },
          //   },
          //   { root: true }
          // );
          if (response.pass) {
            commit("setSnackbar", {
              text: response.message,
              status: "success",
              show: "showNav",
              timeout: 1000,
            });
          } else {
            commit("setSnackbar", {
              text: "Something went wrong",
              status: "danger",
              show: "showNav",
            });
          }
        } catch (error) {
          commit("setSnackbar", {
            text: error.message,
            status: "danger",
            show: "showNav",
          });
        }
      }

      if (response && response.pass) {
        let dataD;
        setTimeout(async function () {
          dataD = await get_depots_all();
          commit("setDepotManage", dataD.data);
          commit("setSpinnerShow", false);
        }, 500);
      } else {
        commit("setSpinnerShow", false);
      }
      return response;
    },

    async getInventoryAllocations({ commit }) {
      commit("setSpinnerShow", true);

      try {
        let { data } = await get_inventory_allocations();
        commit("setInventoryAllocations", data);
        commit("setSnackbar", {
          text: "Inventory Allocation fetched!",
          status: "success",
          show: "showNav",
        });
      } catch (error) {
        console.log(error);
      }
      commit("setSpinnerShow", false);
    },
    async saveInventoryAllocation({ commit, dispatch }, { flagNew, data }) {
      let response;
      console.log(flagNew, data);
      commit("setSpinnerShow", true);
      try {
        response = flagNew.new
          ? await create_inventory_allocation(data)
          : await update_toggle_inventory_allocation(data);
        console.log("index saveInventoryAllocation response", response);
        if (response.pass) {
          commit("setSnackbar", {
            text: response.message,
            status: "success",
            show: "showNav",
            timeout: 1000,
          });
          setTimeout(async () => {
            await dispatch("getInventoryAllocations");
          }, 1000);
        } else {
          commit("setSnackbar", {
            text: response.message,
            status: "danger",
            show: "showNav",
          });
        }
      } catch (error) {
        console.log(error);
        commit("setSnackbar", {
          text: error.message,
          status: "danger",
          show: "showNav",
        });
      }
      commit("setSpinnerShow", false);
    },
    async getCountries({ commit }) {
      commit("setSpinnerShow", true);

      try {
        let { data } = await get_countries();
        commit("setCountries", data);
        commit("setSnackbar", {
          text: "Countries fetched!",
          status: "success",
          show: "showNav",
        });
      } catch (error) {
        console.log(error);
      }
      commit("setSpinnerShow", false);
    },
    async uploadFile({ commit }, formData) {
      commit("setSpinnerShow", true, { root: true });

      try {
        let response;
        response = await uploadFile(formData);
        commit(
          "setSnackbar",
          {
            text: `Uploaded file successfully.`,
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
        if (formData.getAll("docType")[0] === "ORGANIZATION_DOC") {
          response = await getListOfFilesByOrganization(
            formData.getAll("organization_id")[0]
          );
          commit("setListOfFiles", response.files);
          commit("setSpinnerShow", false, { root: true });
          return response;
        } else {
          response = await getFilesBySourceID(formData.getAll("sourceid")[0]);
          commit("setOrderFiles", response.files);
          commit("setSpinnerShow", false, { root: true });
          return response;
        }
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong. ${
              error ? error.data.message : "Server problem"
            }`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
      }
    },
    async uploadMasterContract({ commit }, formData) {
      commit("setSpinnerShow", true, { root: true });

      try {
        let response;
        response = await uploadMasterContract(formData);
        commit(
          "setSnackbar",
          {
            text: `Uploaded file successfully.`,
            status: "success",
            show: "showNav",
          },
          { root: true }
        );

        commit("setSpinnerShow", false, { root: true });

        return response;
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong. ${
              error ? error.data.message : "Server problem"
            }`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
      }
    },
    async deleteFile({ commit }, { filename, organization_id, sourceID }) {
      commit("setSpinnerShow", true, { root: true });
      let response;
      try {
        response = await deleteFile(filename);
        commit(
          "setSnackbar",
          {
            text: `File was deleted successfully.`,
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
        if (organization_id != 0) {
          let { files } = await getListOfFilesByOrganization(organization_id);
          commit("setListOfFiles", files);
        } else {
          let { files } = await getFilesBySourceID(sourceID);
          commit("setOrderFiles", files);
        }
      } catch (error) {
        console.log("Delete file error", error);
        commit(
          "setSnackbar",
          {
            text: `Something went wrong. ${
              error ? error.data.message : "Server problem"
            }`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
      }
      commit("setSpinnerShow", false, { root: true });
      return response;
    },
    async getListOfFiles({ commit }, id) {
      commit("setSpinnerShow", true, { root: true });
      console.log("Action getListOfFiles", id);
      try {
        let { files } = await getListOfFilesByOrganization(id);

        commit("setListOfFiles", files);
        commit(
          "setSnackbar",
          {
            text: "Got all files of your organization!",
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong. ${
              error ? error.data.message : "Server problem"
            }`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
        console.log(error);
      }
      commit("setSpinnerShow", false, { root: true });
    },
    async getOrderFiles({ commit }, { sourceID }) {
      commit("setSpinnerShow", true, { root: true });
      try {
        let { files } = await getFilesBySourceID(sourceID);
        commit("setOrderFiles", files);
        commit(
          "setSnackbar",
          {
            text: "All Order's files  fetched!",
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong. ${
              error ? error.data.message : "Server problem"
            }`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
      }
      commit("setSpinnerShow", false, { root: true });
    },
    async makePublicFile({ commit }, { filename }) {
      commit("setSpinnerShow", true, { root: true });
      let response;
      try {
        response = await makePublicFile(filename);
        console.log("response action", response);
        commit(
          "setSnackbar",
          {
            text: `Now the file is public.`,
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong. ${
              error ? error.data.message : "Server problem"
            }`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
      }
      commit("setSpinnerShow", false, { root: true });
      return response;
    },
    async getSignedURL({ commit }, { filename }) {
      commit("setSpinnerShow", true, { root: true });
      let response;
      try {
        response = await getSignedURL(filename);
        // commit(
        //   "setSnackbar",
        //   {
        //     text: `Now you have a signed url valid for 1 hour.`,
        //     status: "success",
        //     show: "showNav",
        //   },
        //   { root: true },
        // );
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong. ${
              error ? error.data.message : "Server problem"
            }`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
      }
      commit("setSpinnerShow", false, { root: true });
      return response;
    },
    async getListOfCustomFieldOrganization({ commit }, { organization_id }) {
      commit("setSpinnerShow", true, { root: true });
      console.log("Action getListOfCustomFieldOrganization", organization_id);
      try {
        let { data } = await getListOfCustomFieldsForOrganization(
          organization_id
        );
        console.log(data.data);
        commit("setListOfCustomFieldOrganization", data.data);

        commit(
          "setSnackbar",
          {
            text: "Got all custom fields for this organization!",
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong. ${
              error ? error.data.message : "Server problem"
            }`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
        console.log(error);
      }
      commit("setSpinnerShow", false, { root: true });
    },
    async createCustomFieldForOrganization({ commit }, { data }) {
      commit("setSpinnerShow", true, { root: true });
      let response;
      try {
        response = await createCustomFieldForOrganization(data);
        response = await getListOfCustomFieldsForOrganization(
          data.organization_id
        );
        commit("setListOfCustomFieldOrganization", response.data.data);
        commit(
          "setSnackbar",
          {
            text: "Custom Field registred successully!",
            status: "success",
            show: "showNav",
          },
          { root: true },
          3
        );
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong. ${
              error ? error.data.message : "Server problem"
            }`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
        console.log(error);
      }

      commit("setSpinnerShow", false, { root: true });
      return response;
    },
    async deleteCustomField({ commit }, { customFielid, organization_id }) {
      commit("setSpinnerShow", true, { root: true });
      let response;
      try {
        response = await deleteCustomField(customFielid).catch((err) => {
          throw new Error(err);
        });

        commit(
          "setSnackbar",
          {
            text: `Deleted custom field successfully.`,
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
        let { data } = await getListOfCustomFieldsForOrganization(
          organization_id
        );
        commit("setListOfCustomFieldOrganization", data.data);
      } catch (error) {
        console.log("Delete custom field error", error);
        commit(
          "setSnackbar",
          {
            text: `Something went wrong. ${
              error ? error.data.message : "Server problem"
            }`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
      }
      commit("setSpinnerShow", false, { root: true });
      return response;
    },
    async sendInviteEmails({ commit }, data) {
      commit("setSpinnerShow", true, { root: true });

      try {
        await send_invite_emails(data);
        commit(
          "setSnackbar",
          {
            text: "Invitations sent",
            status: "success",
            show: "showNav",
          },
          { root: true }
        );
      } catch (error) {
        commit(
          "setSnackbar",
          {
            text: `Something went wrong. Server problem`,
            status: "danger",
            show: "showNav",
          },
          { root: true }
        );
        console.log(error);
      }
      commit("setSpinnerShow", false, { root: true });
    },
    async addMissingACL({ commit }) {
      commit("setSpinnerShow", true);

      try {
        let { data } = await refreshDepots();
        // commit("setInventoryAllocations", data);
        commit("setSnackbar", {
          text: "Updated all depots.",
          status: "success",
          show: "showNav",
        });
      } catch (error) {
        console.log(error);
      }
      commit("setSpinnerShow", false);
    },

    async generateInviteLink({ commit }) {
      commit("setSpinnerShow", true);
      let res;
      try {
        let { data } = await get_unique_link_invitation();

        res = data;
      } catch (error) {
        console.log(error);

        commit("setSnackbar", {
          text: `Something went wrong. Server problem`,
          status: "danger",
          show: "showNav",
        });
      }
      commit("setSpinnerShow", false);
      return res;
    },

    async sendEmailServerBos(
      { commit, dispatch, rootGetters },
      { toEmail, subject, BOSNumber }
    ) {
      const token = localStorage.getItem("access_token");

      const obj_send_bos = {
        toList: [toEmail],
        ccList: [],
        bccList: [],
        subject: subject,
        BOSNumber,
        url: `${process.env.VUE_APP_FILES_URL}/BOS?invoiceID=${BOSNumber}&header=false&token=${token}`,
      };

      // sent bos
      await sent_serve_bos_email(obj_send_bos);
    },
  },
  modules: {
    moduleOrders: moduleOrders,
    moduleOrganizations: moduleOrganizations,
    moduleQtySumm: moduleQtySumm,
    moduleDepotManage: moduleDepotManage,
    moduleReports: moduleReports,
    moduleAuctions: moduleAuctions,
    moduleInventoryWarnings: moduleInventoryWarnings,
    moduleSQLReports: moduleSQLReports,
    moduleCustomerDistribution: customerDistribution,
    moduleEmails: moduleEmails,
  },
});
